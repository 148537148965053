import React from "react"

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Toolbar,
  Typography,
} from "@mui/material"
import ShareButtons from "../../Components/ShareButtons"

import { Link } from "react-router-dom"
import Grid from "@mui/material/Unstable_Grid2"
import annotationIndustry from "../../assets/articles/annotation-industry.json"
import alibaba from "../../assets/articles/alibaba-tech.json"

import SeoUpdater from "../../Components/SeoUpdater"
export default function Tech(): React.ReactElement {
  return (
    <>
      <SeoUpdater
        headerTags={{
          title: "NewsOne.ai - Technology",
          commaSeparatedKeywords: "News, NewsOne, News One India, News One AI, Tech, Technology",
          titleTemplate: "%s",
          description: "NewsOne.ai - Connecting the dots.",
          imageUrl: "https://www.newsone.ai/images/NewsOneLightLogo.png",
          url: "/tech",
        }}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Grid container spacing={2}>
          <Grid md={12} sm={12} xs={12}>
            <Typography variant="h3" component="h1">
              Technology
            </Typography>
          </Grid>
          <Grid md={8} sm={12} xs={12}>
            <Card>
              <Link
                to={annotationIndustry.url}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <CardMedia
                  sx={{ height: 350 }}
                  image={annotationIndustry.image_url}
                  title="Data annotation for AI."
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {annotationIndustry.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {annotationIndustry.short_text}
                  </Typography>
                </CardContent>
              </Link>
              <CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <Button color="secondary" variant="contained">
                        <Link
                          to={annotationIndustry.url}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Grid>
                    <Grid>
                      <ShareButtons
                        sx={{ p: 0, paddingTop: 0 }}
                        url={`${window.location.origin}${annotationIndustry.url}`}
                        shareText={annotationIndustry.short_text}
                        shareTitle={annotationIndustry.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card>
            <Card>
              <Link to={alibaba.url} style={{ textDecoration: "none", color: "inherit" }}>
                <CardMedia
                  sx={{ height: 350 }}
                  image={alibaba.image_url}
                  title="Data data for AI."
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {alibaba.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {alibaba.short_text}
                  </Typography>
                </CardContent>
              </Link>
              <CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <Button color="secondary" variant="contained">
                        <Link to={alibaba.url} style={{ textDecoration: "none", color: "inherit" }}>
                          Read More
                        </Link>
                      </Button>
                    </Grid>
                    <Grid>
                      <ShareButtons
                        sx={{ p: 0, paddingTop: 0 }}
                        url={`${window.location.origin}${alibaba.url}`}
                        shareText={alibaba.short_text}
                        shareTitle={alibaba.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid md={4} sm={12} xs={12}></Grid>
        </Grid>
      </Box>
    </>
  )
}
