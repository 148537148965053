import React from "react"
import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Chip,
  List,
  Stack,
  type SxProps,
  type Theme,
  Toolbar,
  Typography,
  useTheme,
  ListItemText,
  ListItem,
  type TypographyOwnProps,
} from "@mui/material"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import { Link } from "react-router-dom"
import MUILink from "@mui/material/Link"
import SeoUpdater from "../SeoUpdater"
import ShareButtons from "../ShareButtons"
import { type HeaderTags } from "../SeoUpdater/types"
import {
  type NavItem,
  type Article,
  type ListContentType,
  type TextContentType,
  type ContentType,
  type BlockquoteContentType,
  type NestedListContentType,
  type ExternalLink,
} from "../../types"
import ScrollToTop from "../ScrollToTop"
import CustomBreadcrumb from "../Breadcrumb"
import Grid from "@mui/material/Unstable_Grid2"
import NewsOneLogoLightTheme from "../../assets/logo/newsone-logo-light-theme.png"
import NewsOneLogoDarkTheme from "../../assets/logo/newsone-logo-dark-theme.png"
import { CONTENT_TYPES } from "../../constants"
import { FormatQuote } from "@mui/icons-material"

const getFirstLetterStyle = (hasFirstLetter: boolean): SxProps<Theme> => {
  return hasFirstLetter
    ? {
        "&::first-letter": {
          fontSize: "60px",
          float: "left",
          textTransform: "uppercase",
          lineHeight: 0.94,
          ml: 0,
          mr: 0.5,
          mt: 0,
          mb: 0.5,
        },
      }
    : {}
}

export const poppulateNestedList = (content: NestedListContentType): React.ReactElement => {
  return (
    <List sx={{ listStyle: "unset", pl: 4, pt: 0, pb: 0.5 }}>
      {content.lists.map((item, index) => {
        const anchoredTexts = getAnchoredTexts(item.text, item?.links)
        return (
          <ListItem key={index} sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
            <ListItemText primary={anchoredTexts.length > 0 ? anchoredTexts : item.text} />
            {poppulateNestedList({
              lists: item.lists,
              type: CONTENT_TYPES.NESTED_UNORDERED_LIST,
            })}
          </ListItem>
        )
      })}
    </List>
  )
}

const poppulateListContentType = (content: ListContentType): React.ReactElement => {
  return (
    <List sx={{ listStyle: "decimal", pl: 4 }}>
      {content.texts.map((item, index) => {
        return (
          <ListItem key={index} sx={{ display: "list-item" }}>
            <ListItemText primary={item} />
          </ListItem>
        )
      })}
    </List>
  )
}

const getAnchoredTexts = (
  text: string,
  links?: ExternalLink[],
  variant?: TypographyOwnProps["variant"],
  isBold?: boolean,
): React.ReactElement[] => {
  const theme = useTheme()
  const anchoredTexts: React.ReactElement[] = []
  if (links?.length) {
    let lastLinkIndex = 0
    for (let linkIndex = 0; linkIndex < links.length; linkIndex++) {
      const currentLink = links[linkIndex]
      const linkTextIndex = text.indexOf(currentLink.text)
      if (linkTextIndex === -1) {
        continue
      }
      const leftString = text.substring(lastLinkIndex, linkTextIndex)
      anchoredTexts.push(<>{leftString}</>)
      anchoredTexts.push(
        <MUILink
          rel="noopener"
          target="_blank"
          href={currentLink.url}
          color={theme.palette.text.primary}
          variant={variant ?? "body1"}
          sx={{
            ...(isBold
              ? {
                  fontWeight: "bold",
                }
              : {}),
          }}
        >
          {currentLink.text}
        </MUILink>,
      )
      lastLinkIndex = linkTextIndex + currentLink.text.length
    }
    const remainingString = text.substring(lastLinkIndex, text.length)
    anchoredTexts.push(<>{remainingString}</>)
  }

  return anchoredTexts
}

export const poppulateParagraph = ({
  text,
  hasFirstLetter = false,
  noLineBreakAtEnd,
  links,
  isBold,
}: TextContentType): React.ReactElement => {
  const anchoredTexts = getAnchoredTexts(text, links, "body1", isBold)
  return (
    <>
      <Typography
        sx={{
          ...getFirstLetterStyle(hasFirstLetter),
          ...(isBold
            ? {
                fontWeight: "bold",
              }
            : {}),
        }}
        variant="body1"
      >
        {anchoredTexts.length > 0 ? anchoredTexts : text}
      </Typography>
      {noLineBreakAtEnd ?? false ? <></> : <br />}
    </>
  )
}

const poppulateTextContentType = (textContent: TextContentType): React.ReactElement => {
  const { type, text, links } = textContent
  switch (type) {
    case CONTENT_TYPES.HEADING_TWO: {
      const anchoredTexts = getAnchoredTexts(text, links, "h5")
      return (
        <>
          <Typography variant="h5" component="h2" mb={1}>
            {anchoredTexts.length > 0 ? anchoredTexts : text}
          </Typography>
        </>
      )
    }

    case CONTENT_TYPES.HEADING_THREE: {
      const anchoredTexts = getAnchoredTexts(text, links, "h6")
      return (
        <>
          <Typography variant="h6" component="h3" mb={1}>
            {anchoredTexts.length > 0 ? anchoredTexts : text}
          </Typography>
        </>
      )
    }

    default: // for paragraph
      return poppulateParagraph(textContent)
  }
}

const poppulateBlockquote = ({ quote, author }: BlockquoteContentType): React.ReactElement => {
  return (
    <Stack direction="row" spacing={2} m={3}>
      <Box>
        <FormatQuote
          sx={{
            transform: "rotate(180deg)",
          }}
        />
      </Box>
      <Box>
        <Typography component="blockquote" variant="h6">
          {quote}
        </Typography>
        <Typography
          sx={{
            "&::before": {
              content: "\"—\"",
              mr: 1,
            },
            opacity: 0.6,
          }}
          component="cite"
        >
          {author}
        </Typography>
      </Box>
    </Stack>
  )
}

export const poppulateContentByType = (content: ContentType): React.ReactElement => {
  switch (content.type) {
    case CONTENT_TYPES.HEADING_TWO:
    case CONTENT_TYPES.HEADING_THREE:
    case CONTENT_TYPES.PARAGRAPH:
      return poppulateTextContentType(content as TextContentType)

    case CONTENT_TYPES.BLOCKQUOTE:
      return poppulateBlockquote(content as BlockquoteContentType)

    case CONTENT_TYPES.ORDERED_LIST:
    case CONTENT_TYPES.UNORDERED_LIST:
      return poppulateListContentType(content as ListContentType)

    case CONTENT_TYPES.NESTED_UNORDERED_LIST:
      return poppulateNestedList(content as NestedListContentType)

    default:
      return <></>
  }
}
export default function Blog({
  headerTags,
  article,
  image,
  breadCrumb,
}: {
  headerTags: HeaderTags
  article: Article
  image: string
  breadCrumb: NavItem[]
}): React.ReactElement {
  const theme = useTheme()

  return (
    <>
      <ScrollToTop />
      <Box component="main" sx={{ p: 3 }}>
        <SeoUpdater headerTags={headerTags} canonicalUrl={headerTags.url} />
        <Toolbar />
        <CustomBreadcrumb breadCrumb={breadCrumb} articelTitle={article.title} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} columns={24}>
            <Grid md={17}>
              <Link
                to={article.category_url}
                style={{
                  textDecoration: "none",
                }}
              >
                <Chip
                  clickable
                  sx={{
                    mb: 2,
                    borderRadius: "0px",
                  }}
                  color="error"
                  size="small"
                  variant="filled"
                  label={article.category}
                />
              </Link>
              <Typography variant="h4" component="h1" mb={1}>
                {article.title}
              </Typography>
              <Typography variant="subtitle1" component="h2" color={theme.palette.secondary.light}>
                {article.articleHeading}
              </Typography>
              <Stack direction="row" alignItems="center" alignContent="center" mb={1}>
                <Avatar
                  alt={article.publisher?.name ?? "NewsOne.ai Team"}
                  src={
                    article.publisher
                      ? article.publisher.image
                      : theme.palette.mode === "light"
                      ? NewsOneLogoLightTheme
                      : NewsOneLogoDarkTheme
                  }
                  sx={{
                    "& .MuiAvatar-img": { height: "auto" },
                  }}
                />
                <Typography
                  variant="caption"
                  ml={1}
                  sx={{
                    color: theme.palette.secondary.main,
                    maxWidth: "50%",
                  }}
                >
                  By {article.publisher?.author ?? "The NewsOne.ai Team"}
                </Typography>
                <CalendarTodayIcon
                  sx={{
                    fontSize: theme.typography.caption,
                    color: theme.palette.secondary.main,
                    mt: "-2px",
                    ml: 1,
                    mr: "3px",
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                >
                  {new Date(article.created_at).toDateString().substring(4)}
                </Typography>
              </Stack>
              <Card
                sx={{
                  width: "100%",
                  height: "400px",
                  mb: 2,
                }}
              >
                <CardMedia
                  sx={{
                    width: "100%",
                    height: "400px",
                  }}
                  image={image}
                />
              </Card>
              {article.content.map((contentType, index) => {
                return (
                  <React.Fragment key={index}>{poppulateContentByType(contentType)}</React.Fragment>
                )
              })}
              <ShareButtons
                shareText={article.shortText}
                shareTitle={article.title}
                sx={{ p: 0, marginLeft: -1 }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}
